@import './variables';

::-webkit-scrollbar-button {
    display: none;
    height: 13px;
    border-radius: 0px;
    background-color: $white-100;
}

::-webkit-scrollbar-button:hover {
    background-color: $white-100;
}

::-webkit-scrollbar-thumb {
    background-color: $white-200;
    border: $border-thin;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: $stil-red;
}

::-webkit-scrollbar-track {
    background-color: $white-100;
    border: $border-thin;
    border-radius: 3px;

}

::-webkit-scrollbar-track:hover {
    background-color: $white-100;
}

::-webkit-scrollbar {
    width: 6px;
    border: $border-thin;
    border-radius: 3px;
}

::-webkit-scrollbar:horizontal {
    height: 6px;
    border: $border-thin;
    border-radius: 3px;
}


// alt scrollbar

// ::-webkit-scrollbar {
//     background-color:#fff;
//     width:16px
// }

// __<<ngThemingMigrationEscapedComment0>>__
// ::-webkit-scrollbar-track {
//     background-color:#fff
// }

// __<<ngThemingMigrationEscapedComment1>>__
// ::-webkit-scrollbar-thumb {
//     background-color:#babac0;
//     border-radius:16px;
//     border:4px solid #fff
// }

// __<<ngThemingMigrationEscapedComment2>>__
// ::-webkit-scrollbar-button {display:none}
