@import './variables';

button:focus {
  outline: none !important;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.25rem 1rem;
}

.datatable-body-row.active,
.datatable-body-row.active .datatable-row-group {
  background-color: $white-200 !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.color-10f700 {
  background-color: #10f7009c !important;
}

.color-02d9fa {
  background-color: #02d9fabb !important;
}

.color-80FF80 {
  background-color: #80ff8077;
  border-bottom: 1px solid #51b451c2;
}

.color-E60000,
.color-ff0019 {
  background-color: rgba(230, 0, 0, 0.24) !important;
  border-bottom: 1px solid rgba(230, 0, 0, 0.24);
}

.mat-tab-label,
.mat-tab-link {
  background: $white-100;
  min-width: 0 !important;
  opacity: .8;

  &.mat-tab-label-active {
    color: black;
    opacity: 1;
  }
}

.mat-tab-labels,
.mat-tab-links {
  background: $white-100;
}

.form-group {
  label {
    font-size: 0.85rem;
  }
}

textarea {
  resize: none;
}

// ngx-datatable .mat-form-field-infix {
//   padding: 0px !important;
// }

.mat-tab-body-wrapper {
  flex-grow: 1;
}

app-data-grid-editor .mat-form-field-infix {
      border-top: 0px !important;
      padding: 0px !important;
}

.form-control {
    background-color: $white-100;
    color: $text-color;
    font-size: 0.85rem;
    border: $border-thin;
}

div.ngx-editor-textarea {
    background-color: rgba($white-100, 0.75) !important;
}

ngx-datatable {

  >div.visible {
    height: 100%;
    display: flex;
    flex-direction: column;

    >datatable-body {
      flex-basis: 100%;
    }

    >datatable-footer {
      overflow: hidden;
    }
  }
}

datatable-footer.datatable-footer {
  height: 60px !important;
}

.datatable-footer {
  .page-count {
    height: 40px !important;
    line-height: 40px !important;
    flex: 1 1 15% !important;
  }
}

div.datatable-footer-inner.selected-count {
  height: 40px !important;
}

.mat-tab-label {
  border: $border-thin;
  border-right: none;
  height: 34px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.mat-tree-node {
  min-height: 34px !important;
}
