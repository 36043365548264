@use '@angular/material' as mat;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './assets/styles/globals';
@import './assets/styles/quill-theme';

@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

@include mat.core();

$safetk-client-primary: mat.define-palette(mat.$red-palette);
$safetk-client-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);
$safetk-client-warn: mat.define-palette(mat.$red-palette);
$safetk-client-theme: mat.define-light-theme($safetk-client-primary, $safetk-client-accent, $safetk-client-warn);
@include mat.all-component-themes($safetk-client-theme);

@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/PT_Sans/PTSans-Regular.ttf') format('truetype');
}

@import './assets/styles/variables';
$theme-colors: (primary: $stil-red, secondary: $white-200);
$enable-validation-icons: false;
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~bootstrap/scss/bootstrap';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~@swimlane/ngx-datatable/themes/material';

@import '~ngx-toastr/toastr-bs4-alert';

@import './assets/styles/scrollbar';
@import './assets/styles/datatable-stilwell.scss';


$custom-typography: mat.define-typography-config($font-family: 'PT Sans, sans-serif');

@import './assets/styles/overrides';
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";



label.is-invalid {
    color: rgb(180, 0, 0);
}

html, body {
  height: 100vh;
  margin: 0;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

body {
    margin: 0;
    font-family: 'PT Sans', sans-serif;
    background: url(./assets/background.png) no-repeat center;
    background-size: cover;
}


.permit-owned-snackbar {
  z-index: 100;
}


// THESE SETTINGS BREAK MAT-DIALOG SELECTS
// REMOVAL OF SETTINGS BREAKS BootStrap Modal Selects
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 999999 !important;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 999999 !important;
}

bs-modal-backdrop.modal-backdrop {
  z-index: 1000 !important;
}

modal-container.modal {
  z-index: 1000 !important;
}
